var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"contentWrap",staticClass:"signVar"},[_c('div',{ref:"signContent",staticClass:"sign-content",staticStyle:{"overflow-y":"auto"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFileContent),expression:"showFileContent"}],staticClass:"file-content"},[(_vm.currentFile.fileHtml)?_c('iframe',{staticClass:"doc-iframe",style:({
          width: "100%",
          height: "100%",
        }),attrs:{"srcdoc":_vm.currentFile.fileHtml || '-',"frameborder":"0","id":"MyIFrame"}}):(_vm.currentFile.fileKeyList)?_c('ul',{staticClass:"imgList"},[_vm._l((_vm.currentFile.fileKeyList),function(item,index){return _c('li',{key:index,staticClass:"file-img-list"},[_c('img',{style:({ height: _vm.computedY + 'px' }),attrs:{"src":item,"alt":""}})])}),_vm._l((_vm.currentFile.allVarList),function(variable){return _c('li',{key:variable.componentId,staticClass:"file-variable",style:({
            left: variable.x * _vm.ratio + 'px',
            top: _vm.calcPositionY(variable.y, variable.page, true) + 'px',
            width:
              variable.fontSize * _vm.ratio >= 12
                ? variable.width * _vm.ratio + 'px'
                : (variable.width * _vm.ratio) /
                    ((variable.fontSize * _vm.ratio) / 12) +
                  'px',
            height: variable.height * _vm.ratio + 'px',
            fontSize:
              variable.fontSize * _vm.ratio >= 12
                ? variable.fontSize * _vm.ratio + 'px'
                : 12 + 'px',
            fontFamily: _vm.getCurFontFamilyByValue(variable.font).name,
          })},[_c('div',{style:({
              transform: ("scale(" + (variable.fontSize * _vm.ratio >= 12
                  ? 1
                  : (variable.fontSize * _vm.ratio) / 12) + ")"),
              display: 'inline-block',
              transformOrigin: '0 0',
            })},[_vm._v(" "+_vm._s(variable.varValue ? variable.varValue : variable.varTitle)+" ")])])})],2):_vm._e()]),_c('van-form',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showFileContent),expression:"!showFileContent"}],ref:"form",on:{"failed":_vm.onFailed}},[(_vm.currentFile.fileHtml)?[_vm._l((_vm.currentFile.varList),function(item,index){return [(item.dataType === 0)?[_c('van-field',{key:index,attrs:{"input-align":"right","required":item.requireFlag === 1,"label":item.varTitle,"show-error":false,"error":false,"placeholder":("请输入" + (item.varTitle)),"rules":[
                {
                  trigger: 'onBlur',
                  required: item.requireFlag === 1,
                  validator: function (value) { return _vm.validator0(value, item); },
                  message: function (value) { return _vm.message0(value, item); },
                } ]},scopedSlots:_vm._u([(item.varTip)?{key:"button",fn:function(){return [_c('van-popover',{attrs:{"theme":"dark","trigger":"click"},scopedSlots:_vm._u([{key:"reference",fn:function(){return [_c('img',{staticStyle:{"width":"16px"},attrs:{"src":require("@/assets/imgs/tip.png"),"alt":""}})]},proxy:true}],null,true),model:{value:(item.showPopover),callback:function ($$v) {_vm.$set(item, "showPopover", $$v)},expression:"item.showPopover"}},[_c('div',{staticClass:"item-popover",staticStyle:{"fontSize":"13px","padding":"11px"}},[_vm._v(" "+_vm._s(item.varTip)+" ")])])]},proxy:true}:null],null,true),model:{value:(item.varValue),callback:function ($$v) {_vm.$set(item, "varValue", $$v)},expression:"item.varValue"}})]:_vm._e(),(item.dataType === 1)?[_c('van-field',{key:index,attrs:{"input-align":"right","required":item.requireFlag === 1,"label":item.varTitle,"error":false,"placeholder":("请输入" + (item.varTitle)),"rules":[
                {
                  trigger: 'onBlur',
                  required: item.requireFlag === 1,
                  validator: function (value) { return _vm.validator1(value, item); },
                  message: function (value) { return _vm.message1(value, item); },
                } ]},scopedSlots:_vm._u([(item.varTip)?{key:"button",fn:function(){return [_c('van-popover',{attrs:{"theme":"dark","trigger":"click"},scopedSlots:_vm._u([{key:"reference",fn:function(){return [_c('img',{staticStyle:{"width":"16px"},attrs:{"src":require("@/assets/imgs/tip.png"),"alt":""}})]},proxy:true}],null,true),model:{value:(item.showPopover),callback:function ($$v) {_vm.$set(item, "showPopover", $$v)},expression:"item.showPopover"}},[_c('div',{staticClass:"item-popover",staticStyle:{"fontSize":"13px","padding":"11px"}},[_vm._v(" "+_vm._s(item.varTip)+" ")])])]},proxy:true}:null],null,true),model:{value:(item.varValue),callback:function ($$v) {_vm.$set(item, "varValue", $$v)},expression:"item.varValue"}})]:_vm._e(),(item.dataType === 2)?[_c('van-field',{key:index,attrs:{"readonly":"","clickable":"","input-align":"right","error":false,"label":item.varTitle,"required":item.requireFlag === 1,"value":item.varValue,"placeholder":"点击选择日期","rules":[
                {
                  trigger: 'onChange',
                  required: item.requireFlag === 1,
                } ]},on:{"click":function($event){return _vm.onShowDate(item)}},scopedSlots:_vm._u([(item.varTip)?{key:"button",fn:function(){return [_c('van-popover',{attrs:{"theme":"dark","trigger":"click"},scopedSlots:_vm._u([{key:"reference",fn:function(){return [_c('img',{staticStyle:{"width":"16px"},attrs:{"src":require("@/assets/imgs/tip.png"),"alt":""}})]},proxy:true}],null,true),model:{value:(item.showPopover),callback:function ($$v) {_vm.$set(item, "showPopover", $$v)},expression:"item.showPopover"}},[_c('div',{staticClass:"item-popover",staticStyle:{"fontSize":"13px","padding":"11px"}},[_vm._v(" "+_vm._s(item.varTip)+" ")])])]},proxy:true}:null],null,true)})]:_vm._e()]})]:(_vm.currentFile.fileKeyList)?[_vm._l((_vm.fileList),function(file,i){return [_c('div',{key:'file' + i,staticClass:"fileTitle"},[_vm._v(" "+_vm._s('文件' + (i + 1) + ':' + file.fileName)+" ")]),_vm._l((file.varList),function(item,index){return [(item.dataType === 8)?[_c('van-field',{key:index,attrs:{"input-align":"right","required":item.requireFlag === 1,"label":item.varTitle,"error":false,"show-error":false,"placeholder":("请输入" + (item.varTitle)),"rules":[
                  {
                    trigger: 'onBlur',
                    required: item.requireFlag === 1,
                    validator: function (value) { return _vm.validator0(value, item); },
                    message: function (value) { return _vm.message0(value, item); },
                  } ]},scopedSlots:_vm._u([(item.varTip)?{key:"button",fn:function(){return [_c('van-popover',{staticClass:"var-tip-popover",attrs:{"theme":"dark","trigger":"click","placement":"bottom-end","offset":[20, 8]},nativeOn:{"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"reference",fn:function(){return [_c('img',{staticStyle:{"width":"16px"},attrs:{"src":require("@/assets/imgs/tip.png"),"alt":""}})]},proxy:true}],null,true),model:{value:(item.showPopover),callback:function ($$v) {_vm.$set(item, "showPopover", $$v)},expression:"item.showPopover"}},[_c('div',{staticClass:"item-popover",staticStyle:{"fontSize":"13px","padding":"11px"}},[_vm._v(" "+_vm._s(item.varTip)+" ")])])]},proxy:true}:null],null,true),model:{value:(item.varValue),callback:function ($$v) {_vm.$set(item, "varValue", $$v)},expression:"item.varValue"}})]:_vm._e(),(item.dataType === 2)?[_c('van-field',{key:index,attrs:{"type":"number","input-align":"right","required":item.requireFlag === 1,"label":item.varTitle,"error":false,"show-error":false,"placeholder":("请输入" + (item.varTitle)),"rules":[
                  {
                    trigger: 'onBlur',
                    required: item.requireFlag === 1,
                    validator: function (value) { return _vm.validator0(value, item); },
                    message: function (value) { return _vm.message0(value, item); },
                  } ]},scopedSlots:_vm._u([(item.varTip)?{key:"button",fn:function(){return [_c('van-popover',{staticClass:"var-tip-popover",attrs:{"theme":"dark","trigger":"click","placement":"bottom-end","offset":[20, 8]},scopedSlots:_vm._u([{key:"reference",fn:function(){return [_c('img',{staticStyle:{"width":"16px"},attrs:{"src":require("@/assets/imgs/tip.png"),"alt":""}})]},proxy:true}],null,true),model:{value:(item.showPopover),callback:function ($$v) {_vm.$set(item, "showPopover", $$v)},expression:"item.showPopover"}},[_c('div',{staticClass:"item-popover",staticStyle:{"fontSize":"13px","padding":"11px"}},[_vm._v(" "+_vm._s(item.varTip)+" ")])])]},proxy:true}:null],null,true),model:{value:(item.varValue),callback:function ($$v) {_vm.$set(item, "varValue", $$v)},expression:"item.varValue"}})]:_vm._e(),(item.dataType === 3)?[_c('van-field',{key:index,attrs:{"readonly":"","clickable":"","input-align":"right","name":item.varTitle,"error":false,"show-error":false,"label":item.varTitle,"required":item.requireFlag === 1,"value":item.varValue,"placeholder":"点击选择日期","rules":[
                  {
                    trigger: 'onChange',
                    required: item.requireFlag === 1,
                    message:
                      item.requireFlag === 1
                        ? ((item.varTitle) + "不能为空")
                        : null,
                  } ]},on:{"click-input":function($event){return _vm.onShowDate(item)}},scopedSlots:_vm._u([(item.varTip)?{key:"button",fn:function(){return [_c('van-popover',{staticClass:"var-tip-popover",attrs:{"theme":"dark","trigger":"click","placement":"bottom-end","offset":[20, 8]},scopedSlots:_vm._u([{key:"reference",fn:function(){return [_c('img',{staticStyle:{"width":"16px"},attrs:{"src":require("@/assets/imgs/tip.png"),"alt":""}})]},proxy:true}],null,true),model:{value:(item.showPopover),callback:function ($$v) {_vm.$set(item, "showPopover", $$v)},expression:"item.showPopover"}},[_c('div',{staticClass:"item-popover",staticStyle:{"fontSize":"13px","padding":"11px"}},[_vm._v(" "+_vm._s(item.varTip)+" ")])])]},proxy:true}:null],null,true)})]:_vm._e(),(item.dataType === 4)?[_c('van-field',{key:index,attrs:{"readonly":"","clickable":"","input-align":"right","name":item.varTitle,"error":false,"show-error":false,"label":item.varTitle,"required":item.requireFlag === 1,"value":item.varValue,"placeholder":"点击选中选项","rules":[
                  {
                    trigger: 'onChange',
                    required: item.requireFlag === 1,
                    message:
                      item.requireFlag === 1
                        ? ((item.varTitle) + "不能为空")
                        : null,
                  } ]},on:{"click-input":function($event){return _vm.onShowOption(item)}}})]:_vm._e()]})]})]:_vm._e()],2)],1),_c('div',{staticClass:"footer"},[(_vm.showFileContent)?[_c('div',{staticClass:"btn2",on:{"click":function($event){_vm.showFileContent = false}}},[_vm._v("填写文件内容")])]:[_c('div',{staticClass:"btn1",on:{"click":_vm.catFile}},[_vm._v("查看文件")]),_c('div',{staticClass:"btn2",on:{"click":_vm.save}},[_vm._v("保存")])]],2),_c('van-calendar',{staticClass:"calendar",attrs:{"color":"#1676ff","min-date":new Date(1262278861000),"max-date":new Date(_vm.calendarMaxDate)},on:{"confirm":_vm.onConfirm},model:{value:(_vm.showDate),callback:function ($$v) {_vm.showDate=$$v},expression:"showDate"}}),(_vm.showDate1)?_c('van-popup',{attrs:{"round":"","position":"bottom"},model:{value:(_vm.showDate1),callback:function ($$v) {_vm.showDate1=$$v},expression:"showDate1"}},[_c('van-datetime-picker',{attrs:{"type":"year-month","title":"选择日期","min-date":_vm.minDate,"max-date":_vm.maxDate},on:{"confirm":_vm.confirmDate1},model:{value:(_vm.currentDate),callback:function ($$v) {_vm.currentDate=$$v},expression:"currentDate"}})],1):_vm._e(),(_vm.showDate2)?_c('van-popup',{attrs:{"round":"","position":"bottom"},model:{value:(_vm.showDate2),callback:function ($$v) {_vm.showDate2=$$v},expression:"showDate2"}},[_c('van-picker',{attrs:{"title":"选择年份","show-toolbar":"","columns":_vm.dateColumns},on:{"confirm":_vm.confirmDate2}})],1):_vm._e(),(_vm.showOption)?_c('van-popup',{attrs:{"round":"","position":"bottom"},model:{value:(_vm.showOption),callback:function ($$v) {_vm.showOption=$$v},expression:"showOption"}},[_c('van-picker',{attrs:{"show-toolbar":"","columns":_vm.dateItem && _vm.dateItem.optionList ? _vm.dateItem.optionList : []},on:{"cancel":function($event){_vm.showOption = false},"confirm":_vm.onConfirmOption}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }