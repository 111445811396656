/* eslint-disable max-len */
<template>
  <div class="signSeal" ref="signWrap" v-show="hasInit">
    <div
      class="scrollWrap"
      @scroll.passive="getScrollTop"
      id="scrollWrapId"
      ref="wrap"
      @touchstart="bodyVisible"
    >
      <div
        class="preview-page-content"
        ref="previewContent"
        :style="{
          transformOrigin: `${style.transformOrigin.x}px ${style.transformOrigin.y}px`,
        }"
      >
        <template
          v-if="
            currentFile.currentPositionList &&
              currentFile.currentPositionList.length > 0
          "
        >
          <HasPosition
            :ratio="scaleRatio"
            @pzEnabled="pzEnabled"
            @pzDisabled="pzDisabled"
          />
        </template>
        <template v-else>
          <NoPosition
            :ratio="scaleRatio"
            @pzEnabled="pzEnabled"
            @pzDisabled="pzDisabled"
          />
        </template>
      </div>
    </div>
    <div class="footer" ref="footer" @touchstart="bodyHidden">
      <SealAndName
        @changeMatrix="changeMatrix"
        :tMatrix="tMatrix"
        :hasPosition="hasPosition"
        ref="sealAndName"
        :sealList="sealList"
        :signatureRequire="currentFile.signatureRequire"
        @toggleShow="toggleShow"
        :getFooterHeight="getFooterHeight"
      />
      <div class="btn-content">
        <div class="btn1" v-if="!addClass" @click="onSealShow">
          <SvgIcon type="icon_addSeal" class="add-seal" />
          放置签名
        </div>
        <div class="btn" @click="onClick">
          {{ isLastStep ? '确认签署' : '继续签下一份' }}
        </div>
      </div>
    </div>
    <van-dialog
      v-model="show"
      show-cancel-button
      class="agree-dialog"
      cancel-button-text="拒绝"
      confirm-button-text="我同意"
      @confirm="confirm"
    >
      <!--      <div class="dia-title">尊敬的用户</div>-->
      <div class="dia-content">
        <!--eslint-disable-next-line max-len-->
        <!--        由于此次签署涉及到多个参与方，需要前置位参与方同意并授权{{
          systemInfo.companyName
        }}在后置位参与方全部完成文件内容填写后，统一进行签章，此操作可确保您签署的文件具有法律效力，若您对此操作有疑问，请您联系发起方修改签署流程或拒签文件。-->
        <iframe
          id="agree-doc"
          width="100%"
          height="100%"
          :srcdoc="agreeSignHTML"
          frameborder="0"
        >
        </iframe>
      </div>
    </van-dialog>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import { Dialog, Toast } from 'vant'
  import Api from '@/api/seal'
  import contractSignApi from '@/api/contractSign'
  import { initPinchZoom } from '@/utils/pinchZoom'
  import getSealRect from '@/utils/getSealRect'
  import { calcRatio } from '@/utils/calcRatio'
  import { getBodyClientRect } from '@/utils/dom'
  import NoPosition from './signSeal/noPosition.vue'
  import HasPosition from './signSeal/hasPosition.vue'
  import SealAndName from './signSeal/sealAndName.vue'

  export default {
    provide() {
      return {
        getWrapRect: this.getWrapRect,
      }
    },
    data() {
      return {
        sealList: [],
        show: false,
        addClass: false,
        // 是否是最后一步
        isLastStep: false,
        style: {
          transformOrigin: { x: 0, y: 0 },
        },
        reset: () => {},
        changeMatrix: () => {},
        disable: () => {},
        enable: () => {},
        tMatrix: [],
        hasInit: false,

        agreeSignHTML: '',
        scaleRatio: 343 / 595,
      }
    },
    components: {
      NoPosition,
      HasPosition,
      SealAndName,
      [Dialog.Component.name]: Dialog.Component,
    },
    computed: {
      ...mapState({
        systemInfo: state => state.system,
        currentFile: state => state.contractSign.currentFile,
        agreeSign: state => state.contractSign.agreeSign,
        isFirstAgreeSign: state => state.contractSign.isFirstAgreeSign,
        scrollTop: state => state.contractSign.scrollTop,
        ratio: state => state.contractSign.ratio,
        ratio2: state => state.contractSign.ratio2,
        computedY: state => state.contractSign.computedY,
        myInfo: state => state.user.myInfo,
        fileList: state => state.contractSign.fileList,
      }),
      hasPosition() {
        return (
          this.currentFile.currentPositionList &&
          this.currentFile.currentPositionList.length > 0
        )
      },
    },
    watch: {
      currentFile: {
        async handler(newValue) {
          this.hasInit = false
          const res = await this.$store.dispatch('contractSign/getSignStatus')
          this.isLastStep = res
          this.hasInit = true
          if (newValue.currentPositionList.length === 0) {
            this.$refs.sealAndName.open()
            this.addClass = true
          } else {
            this.$refs.sealAndName.close()
            this.addClass = false
          }
          this.$nextTick(() => {
            this.initPinchZoom()
          })
        },
        immediate: true,
      },
    },
    async created() {
      this.getEmpowerHtml()
      await this.getSealListByStatus()
      await this.setSealDefault()
    },
    mounted() {
      document.title = '文件签署'
      // 初始化手势库
      // this.initPinchZoom()
      this.getContentRatio()
    },
    destroyed() {
      this.bodyVisible()
    },
    methods: {
      getContentRatio() {
        this.scaleRatio = calcRatio()
      },
      getFooterHeight() {
        const { footer } = this.$refs
        const { height } = footer.getBoundingClientRect()
        return height
      },
      writeHTMLToIFrame() {
        if (this.agreeSignHTML) {
          // hack 处理 微信环境下 iframe 在某些机型上会出现白屏的情况，此时再重新write一次可以解决大多数情况
          const iframe = document.getElementById('agree-doc')
          iframe.contentDocument.write(this.agreeSignHTML)
        }
      },
      async getEmpowerHtml() {
        try {
          const html = await contractSignApi.getEmpowerHtml()
          this.agreeSignHTML = `<style>body{width:100% !important;}</style>${html}`
        } catch (error) {
          console.error(error)
        }
      },
      pzEnabled() {
        this.enable()
      },
      pzDisabled() {
        this.disable()
      },
      initPinchZoom() {
        const { previewContent } = this.$refs
        const pz = initPinchZoom(previewContent, {
          minScale: 1,
          maxScale: 2,
        })
        this.reset = pz.resetMatrix
        this.changeMatrix = pz.changeMatrix
        this.disable = pz.disable
        this.enable = pz.enable
        this.tMatrix = pz.tMatrix
      },
      bodyHidden() {
        document.getElementsByTagName('body')[0].style.overflow = 'hidden'
      },
      bodyVisible() {
        document.getElementsByTagName('body')[0].style.overflow = 'visible'
      },
      // 获取容器在页面上的上边距
      getWrapRect() {
        const wrap = this.$refs.signWrap
        return wrap.offsetTop
        // return wrap.getBoundingClientRect()
      },

      getScrollTop() {
        if (!this.timer) {
          this.timer = setTimeout(() => {
            const top = document.querySelector('#scrollWrapId').scrollTop
            this.$store.commit('contractSign/setScrollTop', top)
            clearTimeout(this.timer)
            this.timer = null
          }, 300)
        }
      },
      onTouch() {
        this.$refs.sealAndName.close()
        this.addClass = false
      },
      toggleShow(value) {
        if (value) {
          this.addClass = true
        } else {
          this.addClass = false
        }
      },
      // 显示签名弹框
      onSealShow() {
        this.$refs.sealAndName.toggle()
      },
      async confirm() {
        try {
          this.show = false
          // 只同意一次就可以了
          this.$store.commit('contractSign/setIsFirstAgreeSign', false)
          this.toast = Toast.loading({
            message: '加载中...',
            forbidClick: true,
            loadingType: 'spinner',
          })
          const res = await this.$store.dispatch('contractSign/getSignStatus')
          await this.$store.dispatch('contractSign/addSignAndDate', {
            onlyCheck: true,
          })
          await this.$store.dispatch('contractSign/addEmpowerFile')
          if (res) {
            this.$store.commit('contractSign/setShowSignPassword', true)
          } else {
            await this.$store.dispatch('contractSign/addSignAndDate')
          }
        } catch (error) {
          console.error(error)
        } finally {
          this.toast.clear()
        }
      },
      async getSealListByStatus() {
        await Api.getSealListByStatus().then(res => {
          this.sealList = res
        })
      },

      // 自动盖章 落章
      async setSealDefault() {
        const { clientWidth } = getBodyClientRect()
        this.wrapWidth = clientWidth
        try {
          const temp = this.sealList.map(x => {
            return {
              sealImg: x.sealImg,
              signIdE: x.sealId,
              signatureImg: x.sealImg,
              signatureKey: x.sealUrl,
            }
          })
          const sealAllList = [...this.myInfo.userSignList, ...temp]
          let obj = {}
          this.fileList.forEach(item => {
            item.currentPositionList.forEach(async position => {
              obj = sealAllList.find(e => e.signIdE === position.sealId)
              console.log('obj', obj)
              if (obj) {
                let h = 93
                let w = 93
                if (obj.signatureImg) {
                  const { width, height } = await getSealRect(obj.signatureImg)
                  w = width > 225 ? 225 : width
                  h = width > 225 ? (225 / width) * height : height
                }
                // this.$set(position, 'sealKey', obj.signatureKey)
                // this.$set(position, 'sealImg', obj.signatureImg)
                // this.$set(position, 'sealUrl', obj.signatureKey)
                // this.$set(position, 'sealId', obj.signIdE)
              }
            })
          })
        } catch (error) {
          console.error(error)
        }
      },
      getCurrentY(y, page) {
        return y * this.ratio + (page - 1) * this.computedY - this.scrollTop
      },
      onClick() {
        const that = this
        const { currentPositionList } = this.currentFile
        if (currentPositionList && currentPositionList.length !== 0) {
          const onePos = currentPositionList.find(item => !item.sealKey)
          if (onePos) {
            Toast('还有剩余位置未盖章或签字')
            const temp = this.currentFile.currentPositionList.find(x => {
              return !x.sealKey
            })
            if (temp) {
              console.log('temp', temp)
              const top = this.getCurrentY(temp.y, temp.page)
              const y = temp.y + (temp.page - 1) * this.computedY - 200
              this.changeMatrix(0, y)
              // document.documentElement.scrollTop = top
              // document.body.scrollTop = top
            }
            return
          }
        }
        if (this.agreeSign === 1 && this.isFirstAgreeSign) {
          this.show = true
          this.$nextTick(() => {
            this.writeHTMLToIFrame()
          })
          return
        }
        this.toast = Toast.loading({
          message: '加载中...',
          forbidClick: true,
          loadingType: 'spinner',
        })
        this.$store
          .dispatch('contractSign/getSignStatus')
          .then(res => {
            function success() {}
            this.$store
              .dispatch('contractSign/addSignAndDate', {
                onlyCheck: true,
                success: () => {
                  if (res) {
                    that.$store.commit('contractSign/setShowSignPassword', true)
                  } else {
                    // this.$refs.sealAndName.open()
                    that.$store.dispatch('contractSign/addSignAndDate')
                  }
                },
              })
              .then(() => {})
          })
          .finally(() => {
            this.toast.clear()
          })
      },
    },
  }
</script>
<style lang="less">
  .hasPosition,
  .noPosition {
    .handle {
      min-width: 12px;
      min-height: 12px;
      width: 40%;
      height: 40%;
      max-width: 24px;
      max-height: 24px;
      border: 0;
      background: url('../../assets/imgs/signFile/zoom.svg');
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: contain;
    }
    .handle-br {
      bottom: -1px;
      right: -1px;
      &:before {
        z-index: 1;
        // left: 0;
        // top: 0;
        // right: 0;
        // bottom: 0;
      }
    }
  }
  .draggable {
    .el-icon-delete {
      display: none;
    }
  }
  .active {
    .el-icon-delete {
      display: block;
    }
  }
</style>
<style lang="less" scoped>
  .addBootm {
    padding-bottom: 266px !important;
  }
  .dia-title {
    // height: 40px;
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    // text-align: center;
    color: #677283;
    line-height: 24px;
    padding: 16px 24px 0;
  }
  .dia-content {
    // width: 258px;
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #677283;
    line-height: 24px;
    padding: 0 24px;
    margin-bottom: 20px;
    text-indent: 32px;
  }
  .signSeal {
    .scrollWrap {
      height: calc(100vh - 120px);
      overflow: hidden;
      box-sizing: border-box;
      // overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      .preview-page-content {
        padding-bottom: 230px;
      }
    }
    .file-img-list {
      width: 100%;
      .file-img-item {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
    .footer {
      position: fixed;
      -webkit-transform: translateZ(0);
      z-index: 103;
      bottom: 0;
      left: 0;
      width: 100vw;
      background: #ffffff;
      box-shadow: 0px -6px 10px 0px rgba(230, 234, 237, 0.3);
      .btn-content {
        height: 56px;
        padding: 6px 16px;
        display: flex;
        align-items: center;
      }
      .btn {
        flex: 1;
        height: 44px;
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: center;
        line-height: 44px;
        margin: 0 auto;
        // margin-bottom: 10px;
        color: #fff;
        background: #1676ff;
      }
      .btn1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 10px;
        color: #111a34;
        margin: 0 60px 0 44px;
        .add-seal {
          font-size: 16px;
          margin-bottom: 3px;
        }
      }
    }
  }
</style>
<style lang="less">
  .agree-dialog {
    .van-dialog__content {
      height: 50vh;
      .dia-content {
        display: flex;
        width: 100%;
        height: 100%;
      }
    }
  }

  #agree-doc {
    html {
      body {
        width: 100% !important;
      }
    }
  }
</style>
