var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fileApprova"},[_c('div',{staticClass:"scroll-wrap",attrs:{"id":"scrollWrap"}},[_c('div',{ref:"previewContent",staticClass:"preview-page-content",style:({
        transformOrigin: ((_vm.style.transformOrigin.x) + "px " + (_vm.style.transformOrigin.y) + "px"),
      })},[_c('ul',{ref:"fileList",staticClass:"file-img-list"},[_vm._l((_vm.currentFile.fileKeyList),function(item,index){return _c('li',{key:index,staticClass:"file-img-item"},[_c('img',{style:({ height: _vm.computedY + 'px' }),attrs:{"src":item,"alt":""}})])}),(!_vm.currentFile.fileHtml)?_vm._l((_vm.currentFile.allVarList),function(variable){return _c('li',{key:variable.componentId,staticClass:"file-variable",style:({
              left: variable.x * _vm.ratio + 'px',
              top: _vm.calcPositionY(variable.y, variable.page, true) + 'px',
              width:
                variable.fontSize * _vm.ratio >= 12
                  ? variable.width * _vm.ratio + 'px'
                  : (variable.width * _vm.ratio) /
                      ((variable.fontSize * _vm.ratio) / 12) +
                    'px',
              height: variable.height * _vm.ratio + 'px',
              fontSize:
                variable.fontSize * _vm.ratio >= 12
                  ? variable.fontSize * _vm.ratio + 'px'
                  : 12 + 'px',
              fontFamily: _vm.getCurFontFamilyByValue(variable.font).name,
            })},[_c('div',{style:({
                transform: ("scale(" + (variable.fontSize * _vm.ratio >= 12
                    ? 1
                    : (variable.fontSize * _vm.ratio) / 12) + ")"),
                display: 'inline-block',
                transformOrigin: '0 0',
              })},[_vm._v(" "+_vm._s(variable.varValue ? variable.varValue : variable.varTitle)+" ")])])}):_vm._e()],2)])]),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"btn",on:{"click":_vm.onClick}},[_vm._v("通过审批")])])])}
var staticRenderFns = []

export { render, staticRenderFns }