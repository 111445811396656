<template>
  <SignFileWrap>
    <!-- <div
      class="preview-button"
      v-if="showPreviewButton"
      @click="handleShowFilePreview"
    >
      <van-icon name="search" color="#1676FF" />
      <span class="preview-button-text">预览文件</span>
    </div> -->
    <component
      :is="showCom"
      :ref="showCom"
      @onFileContentShow="onFileContentShow"
    ></component>
    <span> </span>
    <SetPassWord
      v-if="!filePreviewVisible"
      @confirm="onConfirm"
      :signWay="signWay"
    />
    <!-- <FilePreview
      append-to-body
      :showTitle="false"
      showBack
      v-model="filePreviewVisible"
      :file="currentFile"
      class="sign-file-preview"
      @onBack="handleHiddenFilePreview"
    /> -->

    <!--  已经处理完毕的文件，展示 已签署，切换下一份   -->
    <div class="footer" v-if="isCurFileBeHandled && hasUnHandledFile">
      <van-button
        class="footer-button"
        type="primary"
        @click="handleChangeToFirstFileToBeProcess"
        >已签署，切换下一份</van-button
      >
    </div>
    <!--  已经处理完毕的文件，展示 已签署，切换下一份   -->
  </SignFileWrap>
</template>
<script>
  import { mapState } from 'vuex'
  import Api from '@/api/contractSign'
  import { Dialog, Toast } from 'vant'
  import SignFileWrap from '@/components/SignFileWrap.vue'
  import SignSeal from '@/components/sign/signSeal.vue'
  import FileApprova from '@/components/sign/fileApprova.vue'
  import CatFile from '@/components/sign/catFile.vue'
  import SignVar from '@/components/sign/signVar.vue'
  import SetPassWord from '@/components/sign/setPassWord.vue'
  import FilePreview from './Components/FilePreview.vue'

  const unOperateStatusList = [0, 1, 2, 3]

  export default {
    components: {
      SignFileWrap,
      SignSeal,
      FileApprova,
      SignVar,
      CatFile,
      SetPassWord,
      FilePreview,
    },

    // beforeRouteEnter(to, from, next) {
    //   // 如果是从添加签名页面返回的，则不需要展示预览页面
    //   if (from.name === 'addSignName') {
    //     next(vm => {
    //       vm.filePreviewVisible = false
    //     })
    //   }
    //   next()
    // },

    data() {
      return {
        signWay: 0,
        filePreviewVisible: false,

        // 是否展示预览按钮
        showPreviewButton: true,
        defaultName: null,
        fileStatus: 0,
      }
    },
    computed: {
      ...mapState({
        showCom: state => state.contractSign.showCom,
        currentFile: state => state.contractSign.currentFile,
        fileList: state => state.contractSign.fileList,
        isNullifyFalg: state => state.contractSign.isNullifyFalg,
      }),
      // 是否当前文件已经被处理过了
      isCurFileBeHandled() {
        // 待处理的状态 只要不是这几个状态 就是处理完毕了
        return (
          this.currentFile.status >= 0 &&
          !unOperateStatusList.includes(this.currentFile.status)
        )
      },
      // 是否还有待处理的文件
      hasUnHandledFile() {
        if (this.fileList && this.fileList.length) {
          return this.fileList.filter(file =>
            unOperateStatusList.includes(file.status)
          ).length
        }
        return false
      },
    },
    watch: {
      currentFile: {
        handler(newValue) {
          this.showPreviewButton = true
        },
      },
    },
    created() {
      // const subId = 'gmY';
      const {
        subId,
        businessId,
        isLauncher,
        back,
        defaultName,
        masterId,
        fileStatus,
      } = this.$route.query
      if (!back) {
        this.$store.dispatch('fetchUserInfo')
        this.$store.commit('contractSign/resetData')
        this.$store.commit(
          'contractSign/setNullifyFalg',
          Number(fileStatus) === 8
        )
        const width = document.documentElement.clientWidth
        const num = 32 * (width / 375)
        this.$store.commit('contractSign/setRatio', width)
        this.$store.commit('contractSign/setRatio2', width / 375)
        this.$store.dispatch('contractSign/getFileList', {
          subId,
          isInit: true,
        })
      }
      this.$store.commit(
        'contractSign/setNullifyFalg',
        Number(fileStatus) === 8
      )
      this.businessId = businessId
      this.isLauncher = isLauncher
      this.defaultName = defaultName
      this.fileStatus = fileStatus
      this.getMasterInfo(subId)
    },
    methods: {
      async getMasterInfo(subId) {
        let res
        if (this.isNullifyFalg) {
          res = await Api.getInvalidMasterInfo({ subId })
        } else {
          res = await Api.getMasterInfo({ subId })
        }
        // Api.getMasterInfo({
        //   subId,
        // }).then(res => {
        // 0.不指定 1.短信验证 2.密码验证
        this.signWay = res.signWay
        // 否允许接收方拒签文件: 0.不允许 1.允许
        this.allowReturn = res.allowReturn
        // 0.不需要同意 1.需要同意
        this.agreeSign = res.agreeSign
        this.$store.commit('contractSign/setAgreeSign', this.agreeSign)
        // 0.待加入 1.待处理 2.处理中 3.已完成 4.已失效
        this.partStatus = res.partStatus
        this.noCreditUser = res.noCreditUser
        // 是否指定章
        this.$store.dispatch('contractSign/setAssignId', {
          assignId: res.assignId,
          assignType: res.assignType,
        })
        if (this.partStatus === 3 || this.partStatus === 4) {
          this.$router.replace({
            name: 'SignDetail',
            query: {
              businessId: this.businessId,
            },
          })
          return
        }
        if (this.noCreditUser) {
          Dialog.confirm({
            title: '警告',
            message: `警告：签署流程中签署方${this.noCreditUser}未实名签署，生成的文件可能无法律效力（具体以不同国家的法律规定为准），如您有疑虑，请与发起方协商或拒签文件`,
          }).then(() => {})
        }
        // })
      },
      async onConfirm(params) {
        try {
          Toast.loading({
            message: '签署中...',
            forbidClick: true,
            loadingType: 'spinner',
            duration: 0,
          })
          const url = `/signSuccessPage?businessId=${this.businessId}&defaultName=${this.defaultName}&fileStatus=${this.fileStatus}`
          // const url = `/signDetail?businessId=${this.businessId}`
          // const url = { name: 'SignComplete' };
          if (this.showCom === 'SignVar') {
            this.$store
              .dispatch('contractSign/addVariable', {
                ...params,
                cb: () => {
                  this.$router.replace(url)
                },
              })
              .finally(() => {
                Toast.clear()
              })
          } else if (this.showCom === 'SignSeal') {
            // Toast.loading({
            //   message: '加载中...',
            //   forbidClick: true,
            //   loadingType: 'spinner',
            // })
            await this.$store
              .dispatch('contractSign/addSignAndDate', {
                ...params,
                success: () => {
                  this.$router.replace(url)
                },
              })
              .finally(() => {
                Toast.clear()
              })
          } else {
            this.$store
              .dispatch('contractSign/approve', {
                ...params,
                cb: () => {
                  this.$router.replace(url)
                },
              })
              .catch(err => {
                this.$store.commit('contractSign/setErrMsg', err.msg)
              })
              .finally(() => {
                Toast.clear()
              })
          }
        } catch (error) {
          console.error('error', error)
        } finally {
          Toast.clear()
        }
      },

      // 当值为 true 时 则说明当前展示的是文件内容，并非填写变量或者其他的页面
      onFileContentShow(value) {
        this.showPreviewButton = value
      },

      handleShowFilePreview() {
        this.filePreviewVisible = true
      },

      /**
       * 隐藏预览文件
       */
      handleHiddenFilePreview() {
        this.filePreviewVisible = false
      },

      handleChangeToFirstFileToBeProcess() {
        this.$store.dispatch('contractSign/changeToFirstFileToBeProcess')
      },
    },
  }
</script>
<style lang="less" scoped>
  .catFile {
    padding-bottom: 60px;
  }
  .footer {
    z-index: 10;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 8px 8px calc(8px + env(safe-area-inset-bottom));
    background: @WHITE;
    box-shadow: 0px -6px 10px 0px rgba(230, 234, 237, 0.3);
    display: flex;
    align-items: center;
    .footer-button {
      width: 100%;
      height: 44px;
      font-weight: 500;
      font-size: 16px;
    }
  }
</style>

<style lang="less">
  .preview-button {
    position: fixed;
    z-index: 10;
    font-size: 16px;
    right: 18px;
    top: 68px;
    display: flex;
    align-items: center;
    .preview-button-text {
      font-size: 14px;
      margin-left: 4px;
      color: @BLUE;
    }
  }
  .sign-file-preview {
    z-index: 1000 !important;
    top: 50px !important;
    height: calc(100vh - 50px) !important;
  }
</style>
