<template>
  <div class="fileApprova">
    <!-- <ul class="file-img-list" ref="fileList">
      <li
        class="file-img-item"
        v-for="(item, index) in currentFile.fileKeyList"
        :key="index"
      >
        <img :src="item" alt="" @click.self="showImg(index)" />
      </li>
    </ul> -->
    <div class="scroll-wrap" id="scrollWrap">
      <div
        class="preview-page-content"
        ref="previewContent"
        :style="{
          transformOrigin: `${style.transformOrigin.x}px ${style.transformOrigin.y}px`,
        }"
      >
        <ul class="file-img-list" ref="fileList">
          <li
            class="file-img-item"
            v-for="(item, index) in currentFile.fileKeyList"
            :key="index"
          >
            <img :src="item" alt="" :style="{ height: computedY + 'px' }" />
          </li>
          <template v-if="!currentFile.fileHtml">
            <li
              class="file-variable"
              v-for="variable in currentFile.allVarList"
              :key="variable.componentId"
              :style="{
                left: variable.x * ratio + 'px',
                top: calcPositionY(variable.y, variable.page, true) + 'px',
                width:
                  variable.fontSize * ratio >= 12
                    ? variable.width * ratio + 'px'
                    : (variable.width * ratio) /
                        ((variable.fontSize * ratio) / 12) +
                      'px',
                height: variable.height * ratio + 'px',
                fontSize:
                  variable.fontSize * ratio >= 12
                    ? variable.fontSize * ratio + 'px'
                    : 12 + 'px',
                fontFamily: getCurFontFamilyByValue(variable.font).name,
              }"
            >
              <div
                :style="{
                  transform: `scale(${
                    variable.fontSize * ratio >= 12
                      ? 1
                      : (variable.fontSize * ratio) / 12
                  })`,
                  display: 'inline-block',
                  transformOrigin: '0 0',
                }"
              >
                {{ variable.varValue ? variable.varValue : variable.varTitle }}
              </div>
            </li>
          </template>
        </ul>
      </div>
    </div>
    <div class="footer">
      <div class="btn" @click="onClick">通过审批</div>
    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import { Toast, Dialog, ImagePreview } from 'vant'
  import { initPinchZoom } from '@/utils/pinchZoom'
  import { paginationHeight } from '@/common/filePreviewConfig'
  import { calcRatio } from '@/utils/calcRatio'
  import { getCurFontFamilyByValue } from '@/common/font'
  // import Hammer from 'hammerjs';

  export default {
    data() {
      return {
        style: {
          transformOrigin: { x: 0, y: 0 },
        },
        ratio: 343 / 595,

        reset: () => {},
      }
    },
    computed: {
      ...mapState({
        currentFile: state => state.contractSign.currentFile,
        computedY: state => state.contractSign.computedY,
        ratio2: state => state.contractSign.ratio2,
      }),
    },
    watch: {
      currentFile(newValue) {
        this.reset()
      },
    },
    mounted() {
      this.initPinchZoom()
      this.ratio = calcRatio()
    },
    methods: {
      getCurFontFamilyByValue,
      initPinchZoom() {
        const { previewContent } = this.$refs
        const pz = initPinchZoom(previewContent, {
          minScale: 1,
          maxScale: 2,
        })
        this.reset = pz.resetMatrix
      },
      showImg(index) {
        ImagePreview({
          images: this.currentFile.fileKeyList,
          startPosition: index,
          closeOnPopstate: true,
        })
      },
      calcPositionY(y, page, ratio) {
        if (ratio) {
          return (
            y * this.ratio +
            (page - 1) * (this.computedY + paginationHeight * this.ratio2)
          )
        }
        return (
          y + (page - 1) * (this.computedY + paginationHeight * this.ratio2)
        )
      },
      onClick() {
        this.$store.commit('contractSign/setApproveResult', true)
        this.toast = Toast.loading({
          message: '加载中...',
          forbidClick: true,
          loadingType: 'spinner',
        })
        this.$store
          .dispatch('contractSign/getSignStatus')
          .then(res => {
            this.$store
              .dispatch('contractSign/approve', { onlyCheck: true })
              .then(() => {
                if (res) {
                  this.$store.commit('contractSign/setShowSignPassword', true)
                } else {
                  this.$store.dispatch('contractSign/approve')
                }
              })
          })
          .finally(() => {
            this.toast.clear()
          })
      },
    },
  }
</script>
<style lang="less" scoped>
  .fileApprova {
    overflow-y: auto;
    .file-img-list {
      width: 100%;
      position: relative;
      padding-bottom: 56px;
      .file-img-item {
        width: 100%;
        img {
          width: 100%;
        }
      }
      .file-variable {
        position: absolute;
      }
    }
    .footer {
      position: fixed;
      -webkit-transform: translateZ(0);
      bottom: 0;
      left: 0;
      width: 375px;
      height: 70px;
      background: #ffffff;
      box-shadow: 0px -6px 10px 0px rgba(230, 234, 237, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      .btn {
        width: 335px;
        height: 50px;
        background: #1676ff;
        border-radius: 25px;
        font-size: 18px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
        line-height: 50px;
      }
    }
  }
</style>
