<template>
  <div class="signVar" ref="contentWrap">
    <div class="sign-content" style="overflow-y: auto;" ref="signContent">
      <div v-show="showFileContent" class="file-content">
        <iframe
          v-if="currentFile.fileHtml"
          :srcdoc="currentFile.fileHtml || '-'"
          frameborder="0"
          :style="{
            width: `100%`,
            height: `100%`,
          }"
          class="doc-iframe"
          id="MyIFrame"
        ></iframe>
        <ul class="imgList" v-else-if="currentFile.fileKeyList">
          <li
            class="file-img-list"
            v-for="(item, index) in currentFile.fileKeyList"
            :key="index"
          >
            <img :src="item" alt="" :style="{ height: computedY + 'px' }" />
          </li>

          <li
            class="file-variable"
            v-for="variable in currentFile.allVarList"
            :key="variable.componentId"
            :style="{
              left: variable.x * ratio + 'px',
              top: calcPositionY(variable.y, variable.page, true) + 'px',
              width:
                variable.fontSize * ratio >= 12
                  ? variable.width * ratio + 'px'
                  : (variable.width * ratio) /
                      ((variable.fontSize * ratio) / 12) +
                    'px',
              height: variable.height * ratio + 'px',
              fontSize:
                variable.fontSize * ratio >= 12
                  ? variable.fontSize * ratio + 'px'
                  : 12 + 'px',
              fontFamily: getCurFontFamilyByValue(variable.font).name,
            }"
          >
            <div
              :style="{
                transform: `scale(${
                  variable.fontSize * ratio >= 12
                    ? 1
                    : (variable.fontSize * ratio) / 12
                })`,
                display: 'inline-block',
                transformOrigin: '0 0',
              }"
            >
              {{ variable.varValue ? variable.varValue : variable.varTitle }}
            </div>
          </li>
        </ul>
      </div>

      <van-form v-show="!showFileContent" @failed="onFailed" ref="form">
        <template v-if="currentFile.fileHtml">
          <!-- 数据类型 0-文本 1-数字 2-日期 -->
          <template v-for="(item, index) in currentFile.varList">
            <template v-if="item.dataType === 0">
              <van-field
                :key="index"
                v-model="item.varValue"
                input-align="right"
                :required="item.requireFlag === 1"
                :label="item.varTitle"
                :show-error="false"
                :error="false"
                :placeholder="`请输入${item.varTitle}`"
                :rules="[
                  {
                    trigger: 'onBlur',
                    required: item.requireFlag === 1,
                    validator: value => validator0(value, item),
                    message: value => message0(value, item),
                  },
                ]"
              >
                <template #button v-if="item.varTip">
                  <van-popover
                    v-model="item.showPopover"
                    theme="dark"
                    trigger="click"
                  >
                    <div
                      class="item-popover"
                      style="fontSize: 13px;padding: 11px"
                    >
                      {{ item.varTip }}
                    </div>
                    <template #reference>
                      <img
                        src="@/assets/imgs/tip.png"
                        alt=""
                        style="width: 16px"
                      />
                    </template>
                  </van-popover>
                </template>
              </van-field>
            </template>
            <template v-if="item.dataType === 1">
              <van-field
                :key="index"
                v-model="item.varValue"
                input-align="right"
                :required="item.requireFlag === 1"
                :label="item.varTitle"
                :error="false"
                :placeholder="`请输入${item.varTitle}`"
                :rules="[
                  {
                    trigger: 'onBlur',
                    required: item.requireFlag === 1,
                    validator: value => validator1(value, item),
                    message: value => message1(value, item),
                  },
                ]"
              >
                <template #button v-if="item.varTip">
                  <van-popover
                    v-model="item.showPopover"
                    theme="dark"
                    trigger="click"
                  >
                    <div
                      class="item-popover"
                      style="fontSize: 13px;padding: 11px"
                    >
                      {{ item.varTip }}
                    </div>
                    <template #reference>
                      <img
                        src="@/assets/imgs/tip.png"
                        alt=""
                        style="width: 16px"
                      />
                    </template>
                  </van-popover>
                </template>
              </van-field>
            </template>
            <template v-if="item.dataType === 2">
              <van-field
                readonly
                clickable
                input-align="right"
                :key="index"
                :error="false"
                :label="item.varTitle"
                :required="item.requireFlag === 1"
                :value="item.varValue"
                placeholder="点击选择日期"
                @click="onShowDate(item)"
                :rules="[
                  {
                    trigger: 'onChange',
                    required: item.requireFlag === 1,
                  },
                ]"
              >
                <template #button v-if="item.varTip">
                  <van-popover
                    v-model="item.showPopover"
                    theme="dark"
                    trigger="click"
                  >
                    <div
                      class="item-popover"
                      style="fontSize: 13px;padding: 11px"
                    >
                      {{ item.varTip }}
                    </div>
                    <template #reference>
                      <img
                        src="@/assets/imgs/tip.png"
                        alt=""
                        style="width: 16px"
                      />
                    </template>
                  </van-popover>
                </template>
              </van-field>
            </template>
          </template>
        </template>
        <template v-else-if="currentFile.fileKeyList">
          <template v-for="(file, i) in fileList">
            <div class="fileTitle" :key="'file' + i">
              {{ '文件' + (i + 1) + ':' + file.fileName }}
            </div>
            <template v-for="(item, index) in file.varList">
              <!-- 数据类型 8-文本 2-数字 3-日期 4-选项 -->
              <template v-if="item.dataType === 8">
                <van-field
                  :key="index"
                  v-model="item.varValue"
                  input-align="right"
                  :required="item.requireFlag === 1"
                  :label="item.varTitle"
                  :error="false"
                  :show-error="false"
                  :placeholder="`请输入${item.varTitle}`"
                  :rules="[
                    {
                      trigger: 'onBlur',
                      required: item.requireFlag === 1,
                      validator: value => validator0(value, item),
                      message: value => message0(value, item),
                    },
                  ]"
                >
                  <template #button v-if="item.varTip">
                    <van-popover
                      class="var-tip-popover"
                      v-model="item.showPopover"
                      theme="dark"
                      trigger="click"
                      @click.native.stop
                      placement="bottom-end"
                      :offset="[20, 8]"
                    >
                      <div
                        class="item-popover"
                        style="fontSize: 13px;padding: 11px"
                      >
                        {{ item.varTip }}
                      </div>
                      <template #reference>
                        <img
                          src="@/assets/imgs/tip.png"
                          alt=""
                          style="width: 16px"
                        />
                      </template>
                    </van-popover>

                    <!-- <ContentTip :tip="item.varTip" /> -->
                  </template>
                </van-field>
              </template>
              <template v-if="item.dataType === 2">
                <van-field
                  :key="index"
                  v-model="item.varValue"
                  type="number"
                  input-align="right"
                  :required="item.requireFlag === 1"
                  :label="item.varTitle"
                  :error="false"
                  :show-error="false"
                  :placeholder="`请输入${item.varTitle}`"
                  :rules="[
                    {
                      trigger: 'onBlur',
                      required: item.requireFlag === 1,
                      validator: value => validator0(value, item),
                      message: value => message0(value, item),
                    },
                  ]"
                >
                  <template #button v-if="item.varTip">
                    <van-popover
                      class="var-tip-popover"
                      v-model="item.showPopover"
                      theme="dark"
                      trigger="click"
                      placement="bottom-end"
                      :offset="[20, 8]"
                    >
                      <div
                        class="item-popover"
                        style="fontSize: 13px;padding: 11px"
                      >
                        {{ item.varTip }}
                      </div>
                      <template #reference>
                        <img
                          src="@/assets/imgs/tip.png"
                          alt=""
                          style="width: 16px"
                        />
                      </template>
                    </van-popover>
                  </template>
                </van-field>
              </template>
              <template v-if="item.dataType === 3">
                <van-field
                  readonly
                  clickable
                  input-align="right"
                  :name="item.varTitle"
                  :key="index"
                  :error="false"
                  :show-error="false"
                  :label="item.varTitle"
                  :required="item.requireFlag === 1"
                  :value="item.varValue"
                  placeholder="点击选择日期"
                  @click-input="onShowDate(item)"
                  :rules="[
                    {
                      trigger: 'onChange',
                      required: item.requireFlag === 1,
                      message:
                        item.requireFlag === 1
                          ? `${item.varTitle}不能为空`
                          : null,
                    },
                  ]"
                >
                  <template #button v-if="item.varTip">
                    <van-popover
                      class="var-tip-popover"
                      v-model="item.showPopover"
                      theme="dark"
                      trigger="click"
                      placement="bottom-end"
                      :offset="[20, 8]"
                    >
                      <div
                        class="item-popover"
                        style="fontSize: 13px;padding: 11px"
                      >
                        {{ item.varTip }}
                      </div>
                      <template #reference>
                        <img
                          src="@/assets/imgs/tip.png"
                          alt=""
                          style="width: 16px"
                        />
                      </template>
                    </van-popover>
                  </template>
                </van-field>
              </template>
              <template v-if="item.dataType === 4">
                <van-field
                  readonly
                  clickable
                  input-align="right"
                  :name="item.varTitle"
                  :key="index"
                  :error="false"
                  :show-error="false"
                  :label="item.varTitle"
                  :required="item.requireFlag === 1"
                  :value="item.varValue"
                  placeholder="点击选中选项"
                  @click-input="onShowOption(item)"
                  :rules="[
                    {
                      trigger: 'onChange',
                      required: item.requireFlag === 1,
                      message:
                        item.requireFlag === 1
                          ? `${item.varTitle}不能为空`
                          : null,
                    },
                  ]"
                />
              </template>
            </template>
          </template>
        </template>
      </van-form>
    </div>

    <div class="footer">
      <template v-if="showFileContent">
        <div class="btn2" @click="showFileContent = false">填写文件内容</div>
      </template>
      <template v-else>
        <div class="btn1" @click="catFile">查看文件</div>
        <div class="btn2" @click="save">保存</div>
      </template>
    </div>
    <van-calendar
      v-model="showDate"
      class="calendar"
      @confirm="onConfirm"
      color="#1676ff"
      :min-date="new Date(1262278861000)"
      :max-date="new Date(calendarMaxDate)"
    />
    <van-popup v-if="showDate1" v-model="showDate1" round position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="year-month"
        title="选择日期"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="confirmDate1"
      />
    </van-popup>
    <van-popup v-if="showDate2" v-model="showDate2" round position="bottom">
      <van-picker
        title="选择年份"
        show-toolbar
        :columns="dateColumns"
        @confirm="confirmDate2"
      />
    </van-popup>
    <van-popup v-if="showOption" v-model="showOption" round position="bottom">
      <van-picker
        show-toolbar
        :columns="dateItem && dateItem.optionList ? dateItem.optionList : []"
        @cancel="showOption = false"
        @confirm="onConfirmOption"
      />
    </van-popup>
  </div>
</template>
<script>
  import { getCurFontFamilyByValue } from '@/common/font'
  import { mapState } from 'vuex'
  import { Dialog, Toast } from 'vant'
  import Moment from 'moment'
  import { validateNumber } from '@/utils/validate'
  import textBecomeImg from '@/utils/textBecomeImg'
  import { paginationHeight } from '@/common/filePreviewConfig'
  import { formatDate } from '@/common/dateFormatOptions'
  // import ContentTip from './contentTip.vue'

  export default {
    components: {
      // ContentTip,
    },
    data() {
      return {
        showDate: false, // 年月日
        showDate1: false, // 年月
        showDate2: false, // 年
        showOption: false,

        // 是否展示文件内容
        showFileContent: false,

        saving: false,
        minDate: new Date(2020, 0, 1),
        maxDate: new Date(2025, 10, 1),
        calendarMaxDate: new Date().getTime() + 365 * 24 * 60 * 60 * 1000 * 10, // 10年
        currentDate: new Date(),
        dateColumns: [
          '2018年',
          '2019年',
          '2020年',
          '2021年',
          '2022年',
          '2023年',
          '2024年',
          '2025年',
        ],
      }
    },
    watch: {
      showFileContent: {
        handler(newValue) {
          this.$emit('onFileContentShow', newValue)
        },
        immediate: true,
      },
      currentFile: {
        handler(newValue) {
          if (newValue.status === 0) {
            this.$emit('onFileContentShow', this.showFileContent)
          }
        },
        immediate: true,
      },
    },
    computed: {
      ...mapState({
        fileList: state => state.contractSign.fileList,
        currentFile: state => state.contractSign.currentFile,
        computedY: state => state.contractSign.computedY,
        ratio2: state => state.contractSign.ratio2,
      }),
      ratio() {
        return (document.documentElement.clientWidth / 595).toFixed(2)
      },
      varList() {
        this.currentFile.varList.forEach(e => {
          e.showPopover = false
        })
        return this.currentFile.varList
      },
    },
    created() {
      if (window.sessionStorage.isBackCatFile) {
        window.sessionStorage.removeItem('isBackCatFile')
      } else {
        // this.catFile()
      }
    },
    mounted() {
      console.log(this.fileList)
      document.title = '填写文件内容'
      this.calcContentWrapHeight()
      if (this.currentFile.fileHtml) {
        const iframe = document.getElementById('MyIFrame')
        iframe.onload = () => {
          // hack 处理 微信环境下 iframe 在某些机型上会出现白屏的情况，此时再重新write一次可以解决大多数情况
          iframe.contentDocument.write(this.currentFile.fileHtml)
          const iframeDoc = iframe.contentDocument
          const styleFragment = document.createElement('style')
          styleFragment.innerText = `body{margin:0;} #tinymce{width:100% !important;margin:0;overflow-x:hidden;} body{width:100%;} .variables{color: #1676FF;background: #CCE2FF;}`
          iframeDoc.head.append(styleFragment)
          iframeDoc.body.contentEditable = false
        }
      }
    },
    methods: {
      formatDate,
      textBecomeImg,
      getCurFontFamilyByValue,
      async save() {
        let toast
        try {
          if (this.saving) return
          this.saving = true
          await this.$refs.form.validate()

          toast = Toast.loading({
            message: '加载中...',
            forbidClick: true,
            loadingType: 'spinner',
          })

          this.$store
            .dispatch('contractSign/getSignStatus')
            .then(res => {
              this.$store
                .dispatch('contractSign/addVariable', { onlyCheck: true })
                .then(() => {
                  if (res) {
                    this.$store.commit('contractSign/setShowSignPassword', true)
                  } else {
                    this.$store.dispatch('contractSign/addVariable')
                  }
                })
            })
            .finally(() => {
              if (toast) {
                toast.clear()
              }
              this.saving = false
            })
        } catch (error) {
          console.error('error', error)
          this.saving = false
        }
      },

      calcContentWrapHeight() {
        const { contentWrap, signContent } = this.$refs
        signContent.style.height = `calc(100vh - 50px - 56px)`
      },
      catFile() {
        window.sessionStorage.isBackCatFile = true
        this.showFileContent = true
        // const { subId, businessId, isLauncher } = this.$route.query
        // this.$router.push({
        //   path: '/catFile',
        //   query: {
        //     subId,
        //     businessId,
        //     isLauncher,
        //   },
        // })
      },
      onFailed() {},
      // formatDate(date, dateFormat) {
      //   if (dateFormat === 0) {
      //     return Moment(date).format('YYYY/MM/DD')
      //   }
      //   if (dateFormat === 1) {
      //     return Moment(date).format('YYYY-MM-DD')
      //   }
      //   if (dateFormat === 2) {
      //     return Moment(date).format('YYYY年MM月DD日')
      //   }
      //   if (dateFormat === 4) {
      //     return Moment(date).format('YYYY年MM月')
      //   }
      //   if (dateFormat === 5) {
      //     return Moment(date).format('YYYY-MM')
      //   }
      //   if (dateFormat === 6) {
      //     return Moment(date).format('YYYY年')
      //   }
      // },
      onShowOption(item) {
        this.dateItem = item
        this.showOption = true
      },
      onShowDate(item) {
        this.dateItem = item
        switch (item.dateFormat) {
          case 4:
          case 5:
            this.showDate1 = true
            break
          case 6:
            this.showDate2 = true
            break
          default:
            this.showDate = true
            break
        }
      },
      onConfirmOption(data) {
        this.showOption = false
        this.dateItem.varValue = data
      },
      onConfirm(date) {
        this.showDate = false
        this.dateItem.varValue = this.formatDate(date, this.dateItem.dateFormat)
      },
      confirmDate1(value) {
        this.showDate1 = false
        this.dateItem.varValue = this.formatDate(
          value,
          this.dateItem.dateFormat
        )
      },
      confirmDate2(value) {
        this.showDate2 = false
        this.dateItem.varValue = value
      },
      validator0(value, item) {
        const { requireFlag, minLength, maxLength, varTitle } = item
        const min = minLength || 0
        if (requireFlag === 1 && !value) {
          return false
        }
        if (minLength && value && value.length < minLength) {
          return false
        }
        if (maxLength && value && value.length > maxLength) {
          return false
        }
        return true
      },
      message0(value, item) {
        const { requireFlag, minLength, maxLength, varTitle, varValue } = item
        const min = minLength || 0
        if (requireFlag === 1 && !value) {
          return `${varTitle}不能为空`
        }
        if (minLength && value && value.length < minLength) {
          return `长度在 ${min} 到 ${maxLength} 个字符`
        }
        if (maxLength && value && value.length > maxLength) {
          return `长度在 ${min} 到 ${maxLength} 个字符`
        }
        return true
      },

      // 数字类型
      validator1(value, item) {
        const { requireFlag, minLength, maxLength, varTitle } = item
        const min = minLength || 0
        if (requireFlag === 1 && !value) {
          return false
        }
        if (value && maxLength && value > maxLength) {
          return false
        }
        if (value && !validateNumber(value)) {
          return false
        }
        if (value && value < min) {
          return false
        }
        return true
      },
      message1(value, item) {
        const { requireFlag, minLength, maxLength, varTitle } = item
        const min = minLength || 0
        if (requireFlag === 1 && !value) {
          return `${varTitle}不能为空`
        }
        if (value && !validateNumber(value)) {
          return `请输入数字类型的值`
        }
        if (value && maxLength && value > maxLength) {
          return `最大值为${maxLength}`
        }
        if (value && value < min) {
          return `最小值为${min}`
        }
        return ''
      },
      calcPositionY(y, page, ratio) {
        if (ratio) {
          return (
            y * this.ratio +
            (page - 1) * (this.computedY + paginationHeight * this.ratio2)
          )
        }
        return (
          y + (page - 1) * (this.computedY + paginationHeight * this.ratio2)
        )
      },
    },
  }
</script>

<style lang="less">
  .signVar {
    .van-field__error-message {
      text-align: right;
      padding-right: 10px;
    }
  }
</style>
<style lang="less" scoped>
  .signVar {
    .item-popover {
    }
    .sign-content {
      background-color: white;
    }

    /deep/ .van-cell {
      // height: 54px;
      // line-height: 34px;
      overflow: visible;
    }

    .sign-content {
      padding-bottom: 24px;
      .fileTitle {
        padding-left: 10px;
        font-size: 15px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #111a34;
        line-height: 40px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .file-content {
        height: 100%;
        .imgList {
          height: 100%;
          background: #f4f5f6;
          position: relative;
          .file-img-list {
            height: 100%;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .file-img-list + .file-img-list {
            margin-top: 20px;
          }
          .file-variable {
            position: absolute;
          }
        }
      }
    }

    .footer {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100vw;
      height: 56px;
      background: #ffffff;
      box-shadow: 0px -6px 10px 0px rgba(230, 234, 237, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 15px;

      .btn1 {
        flex: 1;
        height: 44px;
        background: #ffffff;
        border: 1px solid #2f86f6;
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: center;
        color: #2f86f6;
        line-height: 44px;
        margin-right: 11px;
      }

      .btn2 {
        flex: 1;
        height: 44px;
        background: #1676ff;
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
        line-height: 44px;
      }
    }
    .calendar {
      /deep/.van-calendar__body {
        .van-calendar__month {
          .van-calendar__days {
            .van-calendar__day {
              height: 44px;
            }
          }
        }
      }
    }
  }
</style>
