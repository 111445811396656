var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"catFile"},[_vm._l((_vm.currentFile.oldPositionList),function(item,index){return [(item.signType == 4)?_vm._l((_vm.pageNum),function(num,index2){return _c('div',{key:index + 'signType4' + index2,class:'signPosition24',style:({
        width: _vm.getWidthAndHeight(item.width / _vm.pageNum) + 'px',
        height: _vm.getWidthAndHeight(item.height) + 'px',
        right: '0px',
        top: item.y * _vm.ratio + index2 * _vm.computedY + 'px',
      })},[_c('img',{style:({
          transform: ("translate(-" + (_vm.getWidthAndHeight(item.width / _vm.pageNum) *
            (num - 1)) + "px"),
        }),attrs:{"src":item.sealKey,"alt":""}})])}):[_c('div',{key:index,class:'signPosition2',style:({
        width: _vm.getWidthAndHeight(item.width) + 'px',
        height: _vm.getWidthAndHeight(item.height) + 'px',
        left: item.x * _vm.ratio + 'px',
        top: item.y * _vm.ratio + (item.page - 1) * _vm.computedY + 'px',
        // width: `${pxToRem(item.width)}rem`,
        // height: `${pxToRem(item.height)}rem`,
      })},[(item.dateFlag)?_c('div',{staticClass:"time"},[_c('img',{staticClass:"currenDateBase64",attrs:{"src":_vm.textBecomeImg(item.signDate, 14, '#000'),"alt":""}})]):_vm._e(),_c('img',{attrs:{"src":item.sealKey,"alt":""}})])]]}),_c('ul',{staticClass:"file-img-list"},_vm._l((_vm.currentFile.fileKeyList),function(item,index){return _c('li',{key:index,staticClass:"file-img-item"},[_c('img',{style:({ height: _vm.computedY + 'px' }),attrs:{"src":item,"alt":""},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.showImg(index)}}})])}),0)],2)}
var staticRenderFns = []

export { render, staticRenderFns }