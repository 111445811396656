<template>
  <div class="catFile">
    <template v-for="(item, index) in currentFile.oldPositionList">
      <template v-if="item.signType == 4">
        <div :class="'signPosition24'" v-for="(num, index2) in pageNum" :key="index + 'signType4' + index2" :style="{
          width: getWidthAndHeight(item.width / pageNum) + 'px',
          height: getWidthAndHeight(item.height) + 'px',
          right: '0px',
          top: item.y * ratio + index2 * computedY + 'px',
        }">
          <img :src="item.sealKey" alt="" :style="{
            transform: `translate(-${getWidthAndHeight(item.width / pageNum) *
              (num - 1)}px`,
          }" />
        </div>
      </template>
      <template v-else>
        <div :class="'signPosition2'" :key="index" :style="{
          width: getWidthAndHeight(item.width) + 'px',
          height: getWidthAndHeight(item.height) + 'px',
          left: item.x * ratio + 'px',
          top: item.y * ratio + (item.page - 1) * computedY + 'px',
          // width: `${pxToRem(item.width)}rem`,
          // height: `${pxToRem(item.height)}rem`,
        }">
          <div v-if="item.dateFlag" class="time">
            <img class="currenDateBase64" :src="textBecomeImg(item.signDate, 14, '#000')" alt="" />
          </div>
          <img :src="item.sealKey" alt="" />
        </div>
      </template>
    </template>
    <ul class="file-img-list">
      <li class="file-img-item" v-for="(item, index) in currentFile.fileKeyList" :key="index">
        <img :src="item" alt="" @click.self="showImg(index)" :style="{ height: computedY + 'px' }" />
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { ImagePreview } from 'vant'
// import Hammer from 'hammerjs';
import { translateSealWidthAndHeight } from '@/utils/pxtorem'
import textBecomeImg from '@/utils/textBecomeImg'
import { getBodyClientRect } from '@/utils/dom'

export default {
  data() {
    return {
      wrapWidth: 0,
    }
  },
  computed: {
    ...mapState({
      currentFile: state => state.contractSign.currentFile,
      ratio: state => state.contractSign.ratio,
      computedY: state => state.contractSign.computedY,
      operateType: state => state.contractSign.operateType,
    }),
    type() {
      if (
        this.operateType === 1 ||
        this.operateType === 2 ||
        this.operateType === 3
      ) {
        return 1
      }
      return 2
    },
    pageNum() {
      return this.currentFile.pageNum
    },
  },
  mounted() {
    const { clientWidth } = getBodyClientRect()
    this.wrapWidth = clientWidth
  },
  methods: {
    textBecomeImg,
    getWidthAndHeight(value) {
      return this.translateSealWidthAndHeight(this.wrapWidth, value)
    },
    translateSealWidthAndHeight,
    showImg(index) {
      ImagePreview({
        images: this.currentFile.fileKeyList,
        startPosition: index,
        closeOnPopstate: true,
      })
    },
  },
}
</script>
<style lang="less" scoped>
.catFile {
  font-size: 16px;
  height: 100%;
  // height: calc(100vh - 48px);
  overflow-y: auto;
  position: relative;
  overflow-x: hidden;

  .file-img-list {
    width: 100%;

    .file-img-item {
      width: 100%;

      img {
        width: 100%;
      }
    }

    .file-img-item+.file-img-item {
      margin-top: 20px;
    }
  }

  .footer {
    position: fixed;
    -webkit-transform: translateZ(0);
    bottom: 0;
    left: 0;
    width: 375px;
    height: 70px;
    background: #ffffff;
    box-shadow: 0px -6px 10px 0px rgba(230, 234, 237, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;

    .btn {
      width: 335px;
      height: 50px;
      background: #1676ff;
      border-radius: 25px;
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
      line-height: 50px;
    }
  }

  .signPosition4 {
    position: absolute;
    width: 53px !important;
    height: 56px !important;
    background: #f4f5f6;
    border: 1px dashed #f15643;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #5f6368;
    line-height: 56px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 74px;
      height: 34px;
      object-fit: contain;
      margin-right: -37px;
    }

    .el-icon-delete {
      background: url('../../assets/imgs/deleteSeal.png') no-repeat center;
      width: 12px;
      height: 13px;
      background-size: 12px 13px;
      position: absolute;
      top: -8px;
      right: -8px;
      z-index: 100;
      cursor: pointer;
    }

    .el-icon-delete2 {
      background: url('../../assets/imgs/deleteSeal.png') no-repeat center;
      width: 12px;
      height: 13px;
      background-size: 12px 13px;
      position: absolute;
      top: -8px;
      left: -8px;
      z-index: 100;
      cursor: pointer;
    }
  }

  .signPosition2 {
    position: absolute;
    z-index: 1;
    //width: 106px !important;
    //height: 56px !important;
    background: transparent;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #5f6368;
    line-height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      // object-fit: contain;
    }

    .time {
      position: absolute;
      bottom: -20px;
      left: -1px;
      right: -1px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      font-size: 10px;

      .currenDateBase64 {
        position: relative;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .signPosition24 {
    position: absolute;
    z-index: 1;
    // width: 53px !important;
    // height: 56px !important;
    background: transparent;
    border: 1px dashed #677283;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #5f6368;
    line-height: 56px;
    overflow: hidden;
    display: flex;
    // justify-content: center;
    align-items: center;

    img {
      height: 100%;
      // width: 74px;
      // height: 34px;
      // object-fit: contain;
      // margin-right: -37px;
    }
  }

  .sealStyle {
    width: 86px !important;
    height: 86px !important;
    border: 1px solid rgba(22, 118, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #5f6368;
    line-height: 56px;
    position: absolute;

    .el-icon-delete {
      background: url('../../assets/imgs/deleteSeal.png') no-repeat center;
      width: 12px;
      height: 13px;
      background-size: 12px 13px;
      position: absolute;
      top: -8px;
      right: -8px;
      z-index: 100;
      cursor: pointer;
    }

    .el-icon-delete2 {
      background: url('../../assets/imgs/deleteSeal.png') no-repeat center;
      width: 12px;
      height: 13px;
      background-size: 12px 13px;
      position: absolute;
      top: -8px;
      left: -8px;
      z-index: 100;
      cursor: pointer;
    }

    img {
      width: 72px;
      height: 72px;
    }
  }

  .sealStyle2 {
    width: 86px !important;
    height: 86px !important;
    border: 1px dashed #677283;
    display: flex;
    background: transparent;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #5f6368;
    line-height: 56px;
    position: absolute;

    .el-icon-delete {
      background: url('../../assets/imgs/deleteSeal.png') no-repeat center;
      width: 12px;
      height: 13px;
      background-size: 12px 13px;
      position: absolute;
      top: -8px;
      right: -8px;
      z-index: 100;
      cursor: pointer;
    }

    .el-icon-delete2 {
      background: url('../../assets/imgs/deleteSeal.png') no-repeat center;
      width: 12px;
      height: 13px;
      background-size: 12px 13px;
      position: absolute;
      top: -8px;
      left: -8px;
      z-index: 100;
      cursor: pointer;
    }

    img {
      width: 72px;
      height: 72px;
    }
  }

  .sealStyle4 {
    width: 43px;
    height: 86px;
    border: 1px solid rgba(22, 118, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #5f6368;
    line-height: 56px;
    position: absolute;
    overflow: hidden;

    .el-icon-delete {
      background: url('../../assets/imgs/deleteSeal.png') no-repeat center;
      width: 12px;
      height: 13px;
      background-size: 12px 13px;
      position: absolute;
      top: -8px;
      right: -8px;
      z-index: 100;
      cursor: pointer;
    }

    img {
      width: 72px;
      height: 72px;
      margin-right: -36px;
    }
  }

  .sealStyle24 {
    width: 43px;
    height: 86px;
    border: 1px solid rgba(22, 118, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #5f6368;
    line-height: 56px;
    position: absolute;
    overflow: hidden;
    background-color: transparent;

    .el-icon-delete {
      background: url('../../assets/imgs/deleteSeal.png') no-repeat center;
      width: 12px;
      height: 13px;
      background-size: 12px 13px;
      position: absolute;
      top: -8px;
      right: -8px;
      z-index: 100;
      cursor: pointer;
    }

    img {
      width: 72px;
      height: 72px;
      margin-right: -36px;
    }
  }
}
</style>
